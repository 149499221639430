import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'

import styles from './About.module.scss'
import { ActionContext } from 'context/ActionProvider'
import { Fade } from 'react-awesome-reveal'

function About() {
  const videoRef = useRef(null)
  const {
    setIsTransparent,
    setLogoWhite,
    setActionFullpage,
    actionFullpage,
  } = useContext(ActionContext)
  const [isPlaySound, setIsPlaySound] = useState(false)

  let audio = new Audio('videos/arche-sound.mp3')

  const handleSound = () => {
    audio.play()
    videoRef.current.play()
    setIsPlaySound(true)

    setTimeout(() => {
      setIsPlaySound(false)
    }, 3500)
  }

  const onSetFunction = (action) => {
    setActionFullpage(action)
  }

  const handleActive = (origin, destination, direction, trigger) => {
    if (destination.anchor === 'section1') {
      setIsTransparent(true)
      setLogoWhite(true)
    } else {
      setTimeout(() => {
        setIsTransparent(false)
        setLogoWhite(false)
      }, 1000)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      actionFullpage?.moveTo('section1')
    }, 1000)
  }, [actionFullpage])

  return (
    <div className={styles.aboutWrapper}>
      <ReactFullpage
        licenseKey={'YOUR_KEY_HERE'}
        scrollingSpeed={1000}
        anchors={['section1', 'section2', 'section3', 'section4']}
        menu={true}
        resetSliders={true}
        onLeave={handleActive}
        render={({ state, fullpageApi }) => {
          onSetFunction(fullpageApi)
          return (
            <ReactFullpage.Wrapper>
              <div className='section'>
                <div className='section-container section-container--background'>
                  <div className='section-content'>
                    <div className='content-text'>
                      <h1 className='text'>
                        Meet <span>Arche</span> ...
                      </h1>
                    </div>
                  </div>
                  <div
                    className='scroll-box'
                    onClick={() => fullpageApi.moveSectionDown()}
                  >
                    Scroll to discover
                    <span className='icon-web-ic-down icon-down'></span>
                  </div>
                </div>
              </div>
              <div className='section'>
                <div className='section-content section-content-2'>
                  <div
                    className={`icon-sound-wrapper ${
                      isPlaySound && 'icon-sound-wrapper--disable'
                    }`}
                    onClick={handleSound}
                  >
                    <video
                      className={`icon-sound ${
                        isPlaySound && 'icon-sound--show'
                      }`}
                      id='video-banner'
                      // autoPlay
                      playsInline
                      // loop
                      muted
                      data-keepPlaying
                      ref={videoRef}
                    >
                      <source src='videos/speaker.mp4' type='video/mp4' />
                      Your browser is not supported!
                    </video>
                    <span
                      className={`icon-web-speaker icon-sound-span ${
                        isPlaySound && 'icon-sound-span--hide'
                      }`}
                    ></span>
                  </div>
                  <h1>
                    <span>Arche</span> Pronounced <span>/a-ki/ (n)</span>,
                    represents the "first principle" in ancient Greek
                    philosophy.
                  </h1>
                </div>
                <div
                  className='scroll-box'
                  onClick={() => fullpageApi.moveSectionDown()}
                >
                  Scroll to discover
                  <span className='icon-web-ic-down icon-down'></span>
                </div>
              </div>
              <div className='section section-3'>
                <div className='section-content section-content--body section-content--section-3'>
                  <div className='section-content-3'>
                    <div className='content-text'>
                      Arche Fund is a venture capital fund designed from the
                      ground up to support bold founders building the future of
                      open internet.
                      <br />
                      <br />
                      Establishing collaborations with founding teams in the
                      earliest possible stages, we invest across the Web3 stack,
                      ranging from layer 1 protocols and infrastructures to
                      consumer-facing applications.
                      <br />
                      <br />
                      We provide founders with capital, know-how, resources, and
                      network they need to reach their full potential.
                    </div>
                  </div>
                </div>

                <div
                  className='scroll-box scroll-box--section-3'
                  onClick={() => fullpageApi.moveSectionDown()}
                >
                  Scroll to discover
                  <span className='icon-web-ic-down icon-down'></span>
                </div>
              </div>

              <div className='section'>
                <div className='section-content section-content-4'>
                  <Fade direction='up'>
                    <h1 className='section-title'>Arche Fund</h1>
                    <div className='text-description'>
                      We are Builders backing Builders with three key pillars
                    </div>
                  </Fade>

                  <div className='list-fund'>
                    <Fade direction='up' delay={500}>
                      <div className='item-fund'>
                        <div className='sub-text'>Arche Capital</div>

                        <div className='text-content'>
                          A leading source of capital, backing groundbreaking
                          ideas in Web3.
                        </div>
                      </div>
                    </Fade>

                    <Fade direction='up' delay={1000}>
                      <div className='item-fund item-fund--item-2'>
                        <div className='sub-text'>Arche Ventures</div>

                        <div className='text-content'>
                          An early-stage venture capital firm, supporting
                          emerging Web3 projects.
                        </div>
                      </div>
                    </Fade>

                    <Fade direction='up' delay={1500}>
                      <div className='item-fund'>
                        <div className='sub-text'>Kompass</div>

                        <div className='text-content'>
                          A Web3-native accelerator program, guiding you
                          throughout the entire journey.
                        </div>

                        <div className='link-direction'>
                          Learn more{' '}
                          <span className='icon-web-ic-right icon-right'></span>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>

                <div
                  className='scroll-box scroll-box--section-3'
                  onClick={() => fullpageApi.moveTo('section1')}
                >
                  Scroll to top
                  <span className='icon-web-ic-down icon-up'></span>
                </div>
              </div>
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </div>
  )
}

export default About
