import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import MyButton from 'pages/components/MyButton'
import { ActionContext } from 'context/ActionProvider'
import images from 'assets/images'

import './Header.scss'
import { isNull } from 'lodash'
import useResizeWidth from 'hooks/useResizeWidth'

const arrLink = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'Portfolio',
    link: '/portfolio',
  },
  {
    name: 'Accelerators',
    isComing: true,
    link: '/accelerators',
  },
  {
    name: 'Writing',
    link: 'https://blog.arche.fund/',
    isBlank: true,
  },
]

const arrSocial = [
  {
    name: 'Twitter (X)',
    link: 'https://twitter.com/ArcheFund',
  },
  {
    name: 'Contact',
    link: 'invest@arche.fund',
    isEmail: true,
  },
  {
    name: 'Careers',
    link: '',
    isComing: true,
  },
]

function Header() {
  const menuRef = useRef(null)
  const { pathname } = useLocation()
  const { widthResize } = useResizeWidth()
  const {
    linkChoose,
    setLinkChoose,
    isTransparent,
    logoWhite,
    setIsClickLogo,
  } = useContext(ActionContext)
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isShowHead, setIsShowHead] = useState(false)
  const isFirstTime = isNull(localStorage.getItem('first-time'))
    ? false
    : localStorage.getItem('first-time')
  const isAbout = pathname === '/about'
  const isPortfolio = pathname === '/portfolio'
  const isTablet = widthResize < 1024

  const handleShowMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  const onDirection = (item) => () => {
    if (item.name === 'Writting') {
      return null
    } else {
      setLinkChoose(item.link)
    }
    setIsShowMenu(!isShowMenu)
  }

  const handleClickOut = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsShowMenu(false)
    }
  }

  const onClickLogo = () => {
    setLinkChoose('/')
    setIsClickLogo(true)

    setTimeout(() => {
      setIsClickLogo(false)
    }, 500)
  }

  useEffect(() => {
    setLinkChoose(pathname)

    setTimeout(
      () => {
        setIsShowHead(true)
      },
      isTablet ? 0 : 12000
    )
  }, [isTablet])

  useEffect(() => {
    window.addEventListener('click', handleClickOut)

    return () => window.removeEventListener('click', handleClickOut)
  }, [menuRef, isShowMenu])

  return (
    <div
      className={`header-wrapper ${
        (isShowHead || isFirstTime) && 'header-wrapper--show-head'
      } ${isTransparent && 'header-wrapper--transparent'} ${
        isAbout && 'header-wrapper--about'
      } ${isPortfolio && 'header-wrapper--portfolio'}`}
      id='header'
      ref={menuRef}
    >
      <div className='header-container'>
        <Link to='/' onClick={onClickLogo}>
          <img
            src={images[logoWhite ? 'logoWhite' : 'logo']}
            alt=''
            className='logo'
          />
        </Link>

        <span
          className={`icon-web-${isShowMenu ? 'close' : 'menu'} icon-menu ${
            isShowMenu && 'icon-menu--close'
          }`}
          onClick={handleShowMenu}
        ></span>
      </div>

      <div className='menu-bar-wrapper'>
        <div className={`menu-bar ${isShowMenu && 'menu-bar--show-menu'}`}>
          <div className='menu-head'>
            {/* <span
              className='icon-web-close icon-close'
              onClick={handleShowMenu}
            ></span> */}
          </div>

          <div className='menu-list'>
            {arrLink.map((item, i) => {
              const menuActive = linkChoose === item.link

              return (
                <Link
                  to={item.link}
                  className={`link-menu ${
                    item.isComing && 'link-menu--disable'
                  } ${menuActive && 'link-menu--active'}`}
                  key={i}
                  onClick={onDirection(item)}
                  target={item.isBlank && '_blank'}
                >
                  {item.name}{' '}
                  {item.isComing && (
                    <span className='coming-soon'>(Coming soon)</span>
                  )}
                  {menuActive && <span className='box-active'></span>}
                </Link>
              )
            })}
          </div>

          <div className='menu-social'>
            {arrSocial.map((item, i) => {
              const menuActive = linkChoose === item.link

              if (!item.isEmail) {
                return (
                  <Link
                    to={item.link}
                    className={`link-menu link-menu--social ${
                      item.isComing && 'link-menu--disable'
                    } ${menuActive && 'link-menu--active'}`}
                    key={i}
                    onClick={onDirection(item)}
                    target='_blank'
                  >
                    {item.name}{' '}
                    {item.isComing && (
                      <span className='coming-soon coming-soon--social'>
                        (Coming soon)
                      </span>
                    )}
                  </Link>
                )
              } else {
                return (
                  <a
                    href={`mailto:${item.link}`}
                    className={`link-menu link-menu--social ${
                      item.isComing && 'link-menu--disable'
                    } ${menuActive && 'link-menu--active'}`}
                    key={i}
                  >
                    {item.name}{' '}
                    {item.isComing && (
                      <span className='coming-soon'>(Coming soon)</span>
                    )}
                  </a>
                )
              }
            })}
          </div>

          <div className='box-button'>
            <MyButton
              url='https://coin98.typeform.com/to/riI3Dqe8
'
              className='button-menu'
              isBlank
            >
              Pitch us
            </MyButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
