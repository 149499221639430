import { routes } from 'commons/routes'
import Header from 'pages/layouts/Header'
import { Route, Routes } from 'react-router-dom'

function App() {
  return (
    <div className='App'>
      <Header />

      <Routes>
        {routes.map((route) => {
          return <Route path={route.path} element={route.component} />
        })}
      </Routes>
    </div>
  )
}

export default App
