import { useLocation } from 'react-router-dom'

const { createContext, useState, useEffect } = require('react')

export const ActionContext = createContext()

export function ActionProvider({ children }) {
  const { pathname } = useLocation()

  const [linkChoose, setLinkChoose] = useState('')
  const [isTransparent, setIsTransparent] = useState(false)
  const [logoWhite, setLogoWhite] = useState(false)
  const [actionFullpage, setActionFullpage] = useState()
  const [isClickLogo, setIsClickLogo] = useState(false)

  useEffect(() => {
    if (pathname !== '/' && pathname !== '/about') {
      setIsTransparent(false)
    } else {
      setIsTransparent(true)
    }

    if (pathname === '/about') {
      setLogoWhite(true)
    } else {
      setLogoWhite(false)
    }
  }, [pathname])

  const values = {
    actionFullpage,
    linkChoose,
    isTransparent,
    logoWhite,
    isClickLogo,
    setIsClickLogo,
    setLinkChoose,
    setIsTransparent,
    setLogoWhite,
    setActionFullpage,
  }

  return (
    <ActionContext.Provider value={values}>{children}</ActionContext.Provider>
  )
}
