import { getLength } from 'commons/functions'
import React, { useContext } from 'react'

import './LogoComponent.scss'
import useResizeWidth from 'hooks/useResizeWidth'
import { Link } from 'react-router-dom'
import { ActionContext } from 'context/ActionProvider'
import images from 'assets/images'

function LogoComponent({ className, isHalf = false }) {
  const { widthResize } = useResizeWidth()
  const { setLinkChoose } = useContext(ActionContext)
  const isMobile = widthResize < 768

  const color04 = isMobile ? '#040404' : '#111111'
  const color11 = isMobile ? '#111111' : '#040404'

  const arrLogo = [
    {
      link: 'https://guildfi.com/',
      image: images.guildfi,
      color: '#111111',
    },
    {
      link: 'https://yunero.io/',
      image: images.yunero,
      color: '#040404',
      isMobile: true,
    },
    {
      link: 'https://aura.network/',
      image: images.aura,
      color: color04,
    },
    {
      link: 'https://ancient8.gg/',
      image: images.ancient8,
      color: color11,
      isBorderRight: true,
    },
    {
      link: 'https://magiceden.io/',
      image: images.magicEden,
      color: color11,
    },
    {
      link: 'https://impossible.finance/',
      image: images.impossible,
      color: color04,
      isMobile: true,
    },
    {
      link: 'https://summonersarena.io/',
      image: images.summoner,
      color: '#040404',
    },
    {
      link: 'https://boba.network/',
      image: images.boba,
      color: '#111111',
      isBorderRight: true,
    },
    {
      link: 'https://injectiveprotocol.com/',
      image: images.injective,
      color: '#111111',
    },
    {
      link: 'https://meritcircle.io/',
      image: images.meritCircle,
      color: '#040404',
      isMobile: true,
    },
    {
      link: 'https://traderjoexyz.com/',
      image: images.tradeJoe,
      color: color04,
    },
    {
      link: 'https://hotcross.com/',
      image: images.hotCross,
      color: color11,
      isBorderRight: true,
    },
    {
      link: 'https://sipher.xyz/',
      image: images.zipher,
      color: color11,
    },
    {
      link: 'https://www.projectserum.com/',
      image: images.serum,
      color: color04,
      isMobile: true,
    },
    {
      link: 'https://defiland.app/',
      image: images.defiLand,
      color: '#040404',
    },
    {
      link: 'https://corite.com/',
      image: images.rite,
      color: '#111111',
      isBorderRight: true,
    },
    {
      link: 'https://perp.com/',
      image: images.protocol,
      color: '#111111',
    },
    {
      link: 'https://derbystars.com/',
      image: images.derby,
      color: '#040404',
      isMobile: true,
    },
    {
      link: 'https://www.mercurial.finance/',
      image: images.meteora,
      color: color04,
    },
    {
      link: 'https://www.symmetry.fi/',
      image: images.symmetry,
      color: color11,
      isBorderRight: true,
    },
    {
      link: 'https://www.pods.finance/',
      image: images.pods,
      color: color11,
    },
    {
      isMobile: true,
      color: color04,
    },
    {
      color: '#040404',
    },
    {
      isBorderRight: true,
      color: '#111111',
    },
  ]
  const arrLogoMobile = [
    {
      link: 'https://guildfi.com/',
      image: images.guildfi,
      color: '#111111',
    },
    {
      link: 'https://yunero.io/',
      image: images.yunero,
      color: '#040404',
      isMobile: true,
    },
    {
      link: 'https://aura.network/',
      image: images.aura,
      color: color04,
    },
    {
      link: 'https://ancient8.gg/',
      image: images.ancient8,
      color: color11,
      isBorderRight: true,
    },
    {
      link: 'https://magiceden.io/',
      image: images.magicEden,
      color: color11,
    },
    {
      link: 'https://impossible.finance/',
      image: images.impossible,
      color: color04,
      isMobile: true,
    },
    {
      link: 'https://summonersarena.io/',
      image: images.summoner,
      color: '#040404',
    },
    {
      link: 'https://boba.network/',
      image: images.boba,
      color: '#111111',
      isBorderRight: true,
    },
    {
      link: 'https://injectiveprotocol.com/',
      image: images.injective,
      color: '#111111',
    },
    {
      link: 'https://meritcircle.io/',
      image: images.meritCircle,
      color: '#040404',
      isMobile: true,
    },
    {
      link: 'https://traderjoexyz.com/',
      image: images.tradeJoe,
      color: color04,
    },
    {
      link: 'https://hotcross.com/',
      image: images.hotCross,
      color: color11,
      isBorderRight: true,
    },
    {
      link: 'https://sipher.xyz/',
      image: images.zipher,
      color: color11,
    },
    {
      link: 'https://www.projectserum.com/',
      image: images.serum,
      color: color04,
      isMobile: true,
    },
    {
      link: 'https://defiland.app/',
      image: images.defiLand,
      color: '#040404',
    },
    {
      link: 'https://corite.com/',
      image: images.rite,
      color: '#111111',
      isBorderRight: true,
    },
    {
      link: 'https://perp.com/',
      image: images.protocol,
      color: '#111111',
    },
    {
      link: 'https://derbystars.com/',
      image: images.derby,
      color: '#040404',
      isMobile: true,
    },
    {
      link: 'https://www.mercurial.finance/',
      image: images.meteora,
      color: color04,
    },
    {
      link: 'https://www.symmetry.fi/',
      image: images.symmetry,
      color: color11,
      isBorderRight: true,
    },
    {
      link: 'https://www.pods.finance/',
      image: images.pods,
      color: color11,
    },
    {
      isMobile: true,
      color: color04,
    },
  ]

  return (
    <div className={`table-icon ${className}`}>
      <div className={`table-head`}>
        <div
          className={`content-table-head ${
            isHalf && 'content-table-head--half'
          }`}
        >
          {getLength(arrLogo) - 3} Startups
          {isHalf && (
            <Link
              to='/portfolio'
              className='see-more'
              onClick={() => setLinkChoose('/portfolio')}
            >
              See more <span className='icon-web-ic-right icon-right'></span>
            </Link>
          )}
        </div>
      </div>

      <div className='table-body'>
        {isMobile
          ? arrLogoMobile.map((item, i) => {
              const isMobileBorder =
                widthResize <= 768 && item.isMobile ? true : false

              return (
                <a href={item.link} key={i} target='_blank' rel='noreferrer'>
                  <div
                    className={`item-icon ${
                      (item.isBorderRight || isMobileBorder) &&
                      'item-icon--border-right'
                    } ${!item.link && 'item-icon--empty-item'}`}
                    style={{ backgroundColor: item?.color }}
                  >
                    <img src={item.image} alt='' className='icon-logo' />
                  </div>
                </a>
              )
            })
          : arrLogo.map((item, i) => {
              const isMobileBorder =
                widthResize <= 768 && item.isMobile ? true : false

              return (
                <a href={item.link} key={i} target='_blank' rel='noreferrer'>
                  <div
                    className={`item-icon ${
                      (item.isBorderRight || isMobileBorder) &&
                      'item-icon--border-right'
                    } ${!item.link && 'item-icon--empty-item'}`}
                    style={{ backgroundColor: item?.color }}
                  >
                    <img src={item.image} alt='' className='icon-logo' />
                  </div>
                </a>
              )
            })}
      </div>
    </div>
  )
}

export default LogoComponent
