import React, { useEffect } from 'react'

import styles from './Portfolio.module.scss'
import LogoComponent from 'pages/components/LogoComponent'
import { Fade } from 'react-awesome-reveal'
import { useNavigate } from 'react-router-dom'

function Portfolio() {
  const { pathname } = useNavigate()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return (
    <div className={styles.portfolioWrapper}>
      <div className='portfolio-container'>
        <Fade direction='up' cascade>
          <h1>
            Our Investments<span>.</span>
          </h1>

          <div className='text-description'>
            We're proud to back founders who relentlessly shape the future of
            Open Internet
          </div>
        </Fade>

        <div className='content-body'>
          <LogoComponent className='logo-content' />
        </div>
      </div>
    </div>
  )
}

export default Portfolio
