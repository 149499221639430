const images = {
  logo: require('./logo.png'),
  logoWhite: require('./logoWhite.svg').default,
  chess: require('./chess.png'),

  // banners
  banner1: require('./banners/banner1.jpg'),

  // icon-logo
  ancient8: require('./icon-logo/ancient8.png'),
  aura: require('./icon-logo/aura.png'),
  boba: require('./icon-logo/boba.png'),
  defiLand: require('./icon-logo/defiLand.png'),
  derby: require('./icon-logo/derby.png'),
  guildfi: require('./icon-logo/guildfi.png'),
  hotCross: require('./icon-logo/hotCross.png'),
  impossible: require('./icon-logo/impossible.png'),
  injective: require('./icon-logo/injective.png'),
  magicEden: require('./icon-logo/magicEden.png'),
  meritCircle: require('./icon-logo/meritCircle.svg').default,
  meteora: require('./icon-logo/meteora.png'),
  pods: require('./icon-logo/pods.png'),
  protocol: require('./icon-logo/protocol.png'),
  rite: require('./icon-logo/rite.png'),
  serum: require('./icon-logo/serum.svg').default,
  summoner: require('./icon-logo/summoner.png'),
  symmetry: require('./icon-logo/symmetry.png'),
  tradeJoe: require('./icon-logo/tradeJoe.png'),
  yunero: require('./icon-logo/yunero.svg').default,
  zipher: require('./icon-logo/zipher.png'),

  // example
  userExample: require('./userExample.png'),
}

export default images
