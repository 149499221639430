import React, { useContext, useEffect, useRef, useState } from 'react'

import styles from './LandingBody.module.scss'
import useResizeWidth from 'hooks/useResizeWidth'
import ReactFullpage from '@fullpage/react-fullpage'
import MyButton from 'pages/components/MyButton'
import images from 'assets/images'
import LogoComponent from 'pages/components/LogoComponent'
import { Fade } from 'react-awesome-reveal'
import { isNull } from 'lodash'
import { ActionContext } from 'context/ActionProvider'
import { getLength } from 'commons/functions'
import { useNavigate } from 'react-router-dom'

const arrMenu = [
  {
    name: 'section1',
    title: 'Home',
  },
  {
    name: 'section2',
    title: 'Our Investments',
  },
  {
    name: 'section3',
    title: `What's in our heads`,
  },
]

const arrListCard = [
  {
    image: images.banner1,
    title: 'Introducing Arche Fund',
    description:
      'Coin98 Ventures rebrands to Arche Fund, a new chapter to provide enhanced support to bold founders and ideas that will shape the future of the internet. Arche is much more than a mere name change; it encapsulates our mission, values, and the panorama of possibilities that await.',
    link: 'https://blog.arche.fund/introducing-arche/',
  },
  // {
  //   image: images.userExample,
  //   title: "ConsenSys: 'Everyone has a piece of the Web3 pie'",
  //   description:
  //     'Conversation between Arche and Marouen Zelleg, Director of Strategic Accounts at Consensys.',
  // },
  // {
  //   image: images.userExample,
  //   title: "ConsenSys: 'Everyone has a piece of the Web3 pie'",
  //   description:
  //     'Conversation between Arche and Marouen Zelleg, Director of Strategic Accounts at Consensys.',
  // },
  // {
  //   image: images.userExample,
  //   title: "ConsenSys: 'Everyone has a piece of the Web3 pie'",
  //   description:
  //     'Conversation between Arche and Marouen Zelleg, Director of Strategic Accounts at Consensys.',
  // },
  // {
  //   image: images.userExample,
  //   title: "ConsenSys: 'Everyone has a piece of the Web3 pie'",
  //   description:
  //     'Conversation between Arche and Marouen Zelleg, Director of Strategic Accounts at Consensys.',
  // },
]

function LandingBody() {
  const navRef = useRef(null)
  const videoRef = useRef(null)
  const boxRef = useRef(null)

  const {
    setIsTransparent,
    actionFullpage,
    setActionFullpage,
    isClickLogo,
  } = useContext(ActionContext)

  const { heightHeader, widthResize } = useResizeWidth()
  const [sectionChoose, setSectionChoose] = useState(arrMenu[0].name)
  const [isHideVideo, setIsHideVideo] = useState(false)
  const isFirstTime = isNull(localStorage.getItem('first-time'))
    ? false
    : localStorage.getItem('first-time')

  const isMobile = widthResize < 768
  const isTablet = widthResize < 1024
  console.log({ boxRef })
  const isHeightBoxImg = boxRef.current && boxRef.current.clientHeight

  const onChooseSection = (section) => () => {
    setSectionChoose(section)
  }

  const onSetFunction = (action) => {
    setActionFullpage(action)
  }

  const onLeave = (origin, destination, direction, trigger) => {
    setSectionChoose(destination.anchor)
  }

  useEffect(() => {
    setTimeout(
      () => {
        setIsHideVideo(true)
      },
      isTablet ? 0 : 19000
    )

    if (!isFirstTime) {
      setTimeout(
        () => {
          localStorage.setItem('first-time', true)
        },
        isTablet ? 0 : 19000
      )
    }
  }, [widthResize])

  useEffect(() => {
    setIsTransparent(true)
    // if (sectionChoose === 'section1') {
    // } else {
    //   setIsTransparent(false)
    // }
  }, [sectionChoose])

  useEffect(() => {
    setTimeout(() => {
      actionFullpage?.moveTo('section1')
    }, 1000)
  }, [actionFullpage])

  useEffect(() => {
    if (isClickLogo) {
      actionFullpage?.moveTo('section1')
    }
  }, [isClickLogo])

  return (
    <div
      className={styles.landingBody}
      // style={{ '--height-header': `${heightHeader}px` }}
    >
      <div className='nav-container'>
        <div className='list-nav' ref={navRef}>
          <Fade
            delay={isFirstTime ? 0 : 12000}
            direction='up'
            cascade={true}
            triggerOnce={true}
          >
            {arrMenu.map((item, i) => {
              const sectionActive = sectionChoose === item.name

              return (
                <div className='item-nav' key={item.name}>
                  <a
                    data-menuanchor={item.name}
                    href={`#${item.name}`}
                    className={`nav-item ${
                      sectionActive && 'nav-item--active'
                    }`}
                    onClick={onChooseSection(item.name)}
                  >
                    <div className='box-nav'>
                      <div className='div-hover'></div>
                    </div>

                    <span className='nav-title'>{item.title}</span>
                  </a>
                </div>
              )
            })}
          </Fade>
        </div>
      </div>

      <div className='mobile-screen'>
        <ReactFullpage
          licenseKey={'YOUR_KEY_HERE'}
          scrollingSpeed={1000}
          anchors={['section1', 'section2', 'section3']}
          menu={true}
          onLeave={onLeave}
          verticalCentered={true}
          fitToSection={false}
          resetSliders={true}
          reBuild={true}
          fadingEffect={true}
          scrollOverflowReset='section1'
          render={({ state, fullpageApi }) => {
            onSetFunction(fullpageApi)
            return (
              <>
                <ReactFullpage.Wrapper>
                  <div className='section section--section-1'>
                    <div className='content-section-1'>
                      <div className='text-content'>
                        <Fade
                          delay={isFirstTime ? 0 : 12000}
                          direction='up'
                          cascade={true}
                          // triggerOnce={true}
                        >
                          <h1 className='text-title text-title--section-1'>
                            We back bold founders building the future of open
                            internet
                            <span>.</span>
                          </h1>

                          {/* <div className='text-description'>
                          Arche Fund invests in founders and ideas that will
                          shape the future of the internet.
                        </div> */}
                        </Fade>
                      </div>

                      <Fade
                        delay={isFirstTime ? 0 : 12000}
                        direction='up'
                        triggerOnce={true}
                        className='btn-pitch-us'
                      >
                        <MyButton
                          url='https://coin98.typeform.com/to/riI3Dqe8
'
                          isBlank
                          className='btn-home'
                        >
                          Pitch us
                        </MyButton>
                      </Fade>

                      <div
                        className={`video-box ${
                          (isHideVideo || isFirstTime) && 'video-box--bg-blur'
                        }`}
                      >
                        {isMobile && (isHideVideo || isFirstTime) && (
                          <video
                            controls={false}
                            className='video-content video-content--stand'
                            id='video-banner'
                            autoPlay
                            playsInline
                            loop
                            muted
                            data-keepPlaying
                            ref={videoRef}
                          >
                            <source
                              src='videos/chess-stand-mobile.mp4'
                              type='video/mp4'
                            />
                            Your browser is not supported!
                          </video>
                        )}
                        {!isMobile && isTablet && (isHideVideo || isFirstTime) && (
                          <video
                            controls={false}
                            className='video-content video-content--stand'
                            id='video-banner'
                            autoPlay
                            playsInline
                            loop
                            muted
                            data-keepPlaying
                            ref={videoRef}
                          >
                            <source
                              src='videos/chess-stand-tablet.mp4'
                              type='video/mp4'
                            />
                            Your browser is not supported!
                          </video>
                        )}
                        {!isMobile &&
                          !isTablet &&
                          (isHideVideo || isFirstTime) && (
                            <video
                              controls={false}
                              className='video-content video-content--stand'
                              id='video-banner'
                              autoPlay
                              playsInline
                              loop
                              muted
                              data-keepPlaying
                              ref={videoRef}
                            >
                              <source
                                src='videos/chess-stand.mp4'
                                type='video/mp4'
                              />
                              Your browser is not supported!
                            </video>
                          )}

                        <video
                          controls={false}
                          className={`video-content video-content--intro ${
                            (isHideVideo || isFirstTime) &&
                            'video-content--hide-video'
                          }`}
                          id='video-banner'
                          autoPlay
                          playsInline
                          // loop
                          muted
                          data-keepPlaying
                          ref={videoRef}
                        >
                          <source
                            src='videos/chess-video.mp4'
                            type='video/mp4'
                          />
                          Your browser is not supported!
                        </video>
                      </div>

                      <div
                        className='scroll-box scroll-box--section-1'
                        onClick={() => fullpageApi.moveSectionDown()}
                      >
                        Scroll to discover
                        <span className='icon-web-ic-down icon-down'></span>
                      </div>
                    </div>
                  </div>

                  <div className='section section--section-2'>
                    <div className='content-section-2'>
                      <div className='text-container'>
                        <div className='text-content text-content--section-2'>
                          <Fade
                            direction='up'
                            cascade={true}
                            delay={isTablet ? 300 : 0}
                            className='text-section-2'
                          >
                            <h3 className='text-title text-title--section-2'>
                              Our Investments
                              <span>.</span>
                            </h3>

                            <div className='text-description text-description--section-2'>
                              We're proud to back founders who relentlessly
                              shape the future of Open Internet
                            </div>
                          </Fade>
                        </div>
                      </div>

                      <div className='logo-container'>
                        <LogoComponent className='logo-content' isHalf />
                      </div>

                      <div
                        className='scroll-box scroll-box--section-2'
                        onClick={() => fullpageApi.moveSectionDown()}
                      >
                        Scroll to discover
                        <span className='icon-web-ic-down icon-down'></span>
                      </div>
                    </div>
                  </div>
                  <div className='section'>
                    <div className='content-section-3'>
                      <div className='text-container text-container--section-3'>
                        <div className='text-content text-content--section-3'>
                          <Fade direction='up' delay={isTablet ? 300 : 0}>
                            <h3 className='text-title'>
                              What's in our heads
                              <span>.</span>
                            </h3>
                          </Fade>
                        </div>
                      </div>

                      <div className='list-wrapper'>
                        <div
                          className={`list-card ${
                            getLength(arrListCard) <= 1 && 'list-card--small'
                          }`}
                        >
                          {arrListCard.map((item, i) => {
                            return (
                              <a
                                href={item.link}
                                target='_blank'
                                className={`item-card ${
                                  getLength(arrListCard) <= 1 &&
                                  'item-card--one-item'
                                }`}
                                key={i}
                                rel='noreferrer'
                              >
                                <div className='box-img'>
                                  <img
                                    src={item.image}
                                    alt=''
                                    className='img-card'
                                  />
                                </div>

                                <div className='text-card'>
                                  <h6 className='text-title'>{item.title}</h6>

                                  <div className='text-description-card text-sm'>
                                    {item.description}
                                  </div>
                                </div>
                              </a>
                            )
                          })}

                          {getLength(arrListCard) >= 5 && (
                            <>
                              <a
                                href='https://blog.arche.fund/'
                                target='_blank'
                                className='item-card item-card--see-more'
                                rel='noreferrer'
                              >
                                <div className='content-see-more'>
                                  <span className='icon-web-arrow-line icon-more'></span>
                                  See more aticles
                                </div>
                              </a>
                              <div className='item-card item-card--empty'></div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactFullpage.Wrapper>
              </>
            )
          }}
        />
      </div>

      <div className='desktop-screen'>
        <ReactFullpage
          licenseKey={'YOUR_KEY_HERE'}
          scrollingSpeed={1000}
          anchors={['section1', 'section2', 'section3']}
          menu={true}
          onLeave={onLeave}
          verticalCentered={true}
          fitToSection={false}
          resetSliders={true}
          reBuild={true}
          fadingEffect={true}
          scrollOverflowReset='section1'
          render={({ state, fullpageApi }) => {
            onSetFunction(fullpageApi)
            return (
              <>
                <ReactFullpage.Wrapper>
                  <div className='section'></div>

                  <div className='section'></div>

                  <div className='section'></div>
                </ReactFullpage.Wrapper>
              </>
            )
          }}
        />

        <div
          className={` section-scroll  section-scroll--section-1 ${
            sectionChoose === 'section1' && ' section-scroll--show'
          } ${sectionChoose !== 'section1' && ' section-scroll--hide'}`}
        >
          <div className='content-section-1'>
            <div className='text-content'>
              <Fade
                delay={isFirstTime ? 0 : 12000}
                direction='up'
                cascade={true}
                // triggerOnce={true}
              >
                <h1
                  className={`text-title text-title--section-1 ${
                    sectionChoose === 'section1' && 'text-title--show'
                  }`}
                >
                  We back bold founders building the future of open internet
                  <span>.</span>
                </h1>
              </Fade>
            </div>

            <Fade
              delay={isFirstTime ? 0 : 12000}
              direction='up'
              triggerOnce={true}
              className='btn-pitch-us'
            >
              <MyButton
                url='https://coin98.typeform.com/to/riI3Dqe8
'
                isBlank
                className='btn-home'
              >
                Pitch us
              </MyButton>
            </Fade>

            <div
              className={`video-box ${
                (isHideVideo || isFirstTime) && 'video-box--bg-blur'
              }`}
            >
              {isMobile && (isHideVideo || isFirstTime) && (
                <video
                  controls={false}
                  className='video-content video-content--stand'
                  id='video-banner'
                  autoPlay
                  playsInline
                  loop
                  muted
                  data-keepPlaying
                  ref={videoRef}
                >
                  <source
                    src='videos/chess-stand-mobile.mp4'
                    type='video/mp4'
                  />
                  Your browser is not supported!
                </video>
              )}
              {!isMobile && isTablet && (isHideVideo || isFirstTime) && (
                <video
                  controls={false}
                  className='video-content video-content--stand'
                  id='video-banner'
                  autoPlay
                  playsInline
                  loop
                  muted
                  data-keepPlaying
                  ref={videoRef}
                >
                  <source
                    src='videos/chess-stand-tablet.mp4'
                    type='video/mp4'
                  />
                  Your browser is not supported!
                </video>
              )}
              {!isMobile && !isTablet && (isHideVideo || isFirstTime) && (
                <video
                  controls={false}
                  className='video-content video-content--stand'
                  id='video-banner'
                  autoPlay
                  playsInline
                  loop
                  muted
                  data-keepPlaying
                  ref={videoRef}
                >
                  <source src='videos/chess-stand.mp4' type='video/mp4' />
                  Your browser is not supported!
                </video>
              )}

              <video
                controls={false}
                className={`video-content video-content--intro ${
                  (isHideVideo || isFirstTime) && 'video-content--hide-video'
                }`}
                id='video-banner'
                autoPlay
                playsInline
                // loop
                muted
                data-keepPlaying
                ref={videoRef}
              >
                <source src='videos/chess-video.mp4' type='video/mp4' />
                Your browser is not supported!
              </video>
            </div>

            <div
              className='scroll-box scroll-box--section-1'
              // onClick={() => fullpageApi.moveSectionDown()}
            >
              Scroll to discover
              <span className='icon-web-ic-down icon-down'></span>
            </div>
          </div>
        </div>

        <div
          className={` section-scroll  section-scroll--section-2 ${
            sectionChoose === 'section2' && ' section-scroll--show'
          }`}
        >
          <div className='content-section-2'>
            <div className='text-container'>
              <div className='text-content text-content--section-2'>
                <Fade
                  direction='up'
                  cascade={true}
                  delay={isTablet ? 300 : 0}
                  className='text-section-2'
                >
                  <h3
                    className={`text-title text-title--section-2 ${
                      sectionChoose === 'section2' && 'text-title--show'
                    }`}
                  >
                    Our Investments
                    <span>.</span>
                  </h3>

                  <div
                    className={`text-description text-description--section-2 ${
                      sectionChoose === 'section2' && 'text-description--show'
                    }`}
                  >
                    We're proud to back founders who relentlessly shape the
                    future of Open Internet
                  </div>
                </Fade>
              </div>
            </div>

            <div className='logo-container'>
              <LogoComponent className='logo-content' isHalf />
            </div>

            <div className='scroll-box scroll-box--section-2'>
              Scroll to discover
              <span className='icon-web-ic-down icon-down'></span>
            </div>
          </div>
        </div>
        <div
          className={` section-scroll  section-scroll--section-3 ${
            sectionChoose === 'section3' && ' section-scroll--show'
          }`}
        >
          <div className='content-section-3'>
            <div className='text-container text-container--section-3'>
              <div className='text-content text-content--section-3'>
                <Fade direction='up' delay={isTablet ? 300 : 0}>
                  <h3
                    className={`text-title text-title--section-3 ${
                      sectionChoose === 'section3' && 'text-title--show'
                    }`}
                  >
                    What's in our heads
                    <span>.</span>
                  </h3>
                </Fade>
              </div>
            </div>

            <div className='list-wrapper'>
              <div
                className={`list-card ${
                  getLength(arrListCard) <= 1 && 'list-card--small'
                }`}
              >
                {arrListCard.map((item, i) => {
                  return (
                    <a
                      href={item.link}
                      target='_blank'
                      className={`item-card ${
                        getLength(arrListCard) <= 1 && 'item-card--one-item'
                      }`}
                      key={i}
                      rel='noreferrer'
                    >
                      <div className='box-img' ref={boxRef}>
                        <img src={item.image} alt='' className='img-card' />
                      </div>

                      <div className='text-card'>
                        <h6 className='text-title'>{item.title}</h6>

                        <div className='text-description-card text-sm'>
                          {item.description}
                        </div>
                      </div>
                    </a>
                  )
                })}

                {getLength(arrListCard) >= 5 && (
                  <>
                    <a
                      href='https://blog.arche.fund/'
                      target='_blank'
                      className='item-card item-card--see-more'
                      rel='noreferrer'
                    >
                      <div className='content-see-more'>
                        <span className='icon-web-arrow-line icon-more'></span>
                        See more aticles
                      </div>
                    </a>
                    <div className='item-card item-card--empty'></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingBody
