import About from 'pages/About'
import LandingBody from 'pages/LandingBody'
import Portfolio from 'pages/Portfolio'

const routes = [
  {
    title: 'Home',
    path: '/',
    component: <LandingBody />,
  },
  {
    title: 'About',
    path: '/about',
    component: <About />,
  },
  {
    title: 'Portfolio',
    path: '/portfolio',
    component: <Portfolio />,
  },
]

export { routes }
